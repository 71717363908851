import React, { useReducer, useEffect, useState } from 'react'

import {
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
    Typography,
    Grid,
    Paper,
} from '@mui/material';

import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddIcon from '@mui/icons-material/Add';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

import {
    NewScalesDialog,
    ConfirmationBox,
    DecomposeDialog,
    CatchProperty,
    LoadingOverlay,
    SnackAlert,
    TabPanel,
    StackedLineChart,
    DebugDialog
} from 'components'

import {
    Utilities,
    ApiClient,
} from 'utils'

import "./styles.css";

import { reducer, ActionKind as ACTIONS } from './partials/reducer';
import moment from "moment"

interface MainProps {
    auth: string,
    setNavigation: Function
}


const util = new Utilities;

//Change the re-do to unload
//Add a resest button to the catchment properties tab
//Add data template to the menu with test data


export const Main: React.FC<MainProps> = ({ auth, setNavigation }) => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        selected: undefined,
        profiles: [],
        debugData: [],
        decompData: [],
        dataset: undefined,
        chartConfig: undefined,
        showConfirmationBox: false,
        showNewScalesBox: false,
        showDecomposeBox: false,
        showDebugBox: false,
        showCatchProp: false,
        newScalesFile: undefined,
        scaleContext: [],
        scaleLabel:[],
        alert: {
            toggled: false,
            title: '',
            severity: 'info',
            message: ''
        }
    });

    //New code here

    const initalState = {
        RainLowThresh: 0.6,
        FlowFrac: 0.08,
        DecayFactor: 0.1,
        SRCThresh: 10,
        InitialLoss: 0.6,
        InitialLossRecharge: 0.1,
        HydroReduction: 0.9,
        Routing: 0.8,
        InterpolatMethod: "InterpolateNatural"
    }

    const [InputData, setInputData] = useState();
    const [ButtonColor, setButtonColor] = useState("");
    const [DecompButtonHover, setDecompButtonHover] = useState("");

    //Catchment Properties
    const [AveWastewater, setAveWastewater] = useState(0.1);
    const [FRCArea, setFRCArea] = useState(10);
    const [RainLowThresh, setRainLowThresh] = useState(initalState.RainLowThresh)
    const [FlowFrac, setFlowFrac] = useState(initalState.FlowFrac)
    const [DecayFactor, setDecayFactor] = useState(initalState.DecayFactor)
    const [SRCThresh, setSRCThresh] = useState(initalState.SRCThresh)
    const [InitialLoss, setInitialLoss] = useState(initalState.InitialLoss)
    const [InitialLossRecharge, setInitialLossRecharge] = useState(initalState.InitialLossRecharge)
    const [HydroReduct, setHydroReduct] = useState(initalState.HydroReduction)
    const [Routing, setRouting] = useState(initalState.Routing)
    const [InterpolatMethod, setInterpolate] = useState(initalState.InterpolatMethod)
    
    const catchInput = {
        rainLowThresh:RainLowThresh,
        flowFrac:FlowFrac,
        decayFactor:DecayFactor,
        srcThresh:SRCThresh,
        initialLoss:InitialLoss,
        initialLossRecharge: InitialLossRecharge,
        hydroReduct: HydroReduct,
        routing: Routing,
        interpolate: InterpolatMethod
    }

    useEffect(() => {
        InputData === undefined ?  setButtonColor("#0D3958") : setButtonColor("#53A545")
        InputData === undefined ?  setDecompButtonHover('') : setDecompButtonHover("#1F6C06")
    }, [InputData])

    const onFileChange = async (event: any) => {

        if (event.target.files[0].type === 'text/csv') {
            var json = await util.ReadCSVtoJson(event.target.files[0]);

            if(Object.keys(json)[0] === 'error'){
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `There is an error in the input file on line: ${json.error + 1}`
                }})
                event.target.value ='';
                return
            }

            if (json.length === 0) {
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `csv-file not valid, headers order and title must be: datetime, flowData, rainData`
                }})
                event.target.value ='';
                return
            }

            if(!moment(json[0].datetime,"YYYY-MM-DDTHH:mm:ss.SSS",true).isValid()){
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `csv-file not valid, datetime format must be YYYY-MM-DDTHH:mm:ss.SSS`
                }})
                event.target.value ='';
                return                
            }
            //This checks if rain was input
            if(Object.keys(json[0]).length === 2){
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `csv-file not valid, headers order and title must be: datetime, flowData, rainData`
                }})
                event.target.value ='';
                return           
            }
            setInputData(json)
            event.target.value ='';
        }
        else{
            dispatch({type: ACTIONS.SET_ALERT, payload:{
                title: "Check of data-file.",
                severity: "warning",
                message: `Please upload a csv or text file`
            }})
            event.target.value ='';
            return
        }
    }

    const updateCatchProp = (file:any) => {
        setRainLowThresh(file.rainLowThresh)
        setFlowFrac(file.flowFrac)
        setDecayFactor(file.decayFactor)
        setSRCThresh(file.srcThresh)
        setInitialLoss(file.initialLoss)
        setInitialLossRecharge(file.initialLossRecharge)
        setHydroReduct(file.hydroReduct)
        setRouting(file.routing)
        setInterpolate(file.interpolate)
    }

    const client = new ApiClient(auth,process.env.REACT_APP_API_BASE);

    const sendDecomposition = async(InputData:any) => {
        try{
            if(InputData === undefined){
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: 'Input data missing',
                    severity:'error',
                    message: 'Please input data for decomposition'
                }})        
            }
            else {
                dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})
                const scalespayload = JSON.stringify({
                    dataTS: InputData,
                })
                const scalesrequest = await client.post<any,any>('DMdecomposition',scalespayload)

                if(Object.keys(scalesrequest).length === 0){
                    dispatch({type: ACTIONS.SET_ALERT, payload:{
                        title: 'Error creating scales',
                        severity:'error',
                        message: 'Something went wrong when creating your scales. Check your inputs.'
                    }})
                } else {

                    const scalesdata = {
                        avgWW: scalesrequest.avgWW,
                        day: scalesrequest.day,
                        year: scalesrequest.year,
                        scalesDayData: scalesrequest.dayData,
                        dryData: scalesrequest.processedDryDays,
                        datastring: scalesrequest.datastring
                    }

                    const decomppayload = JSON.stringify({
                        frcArea:FRCArea,
                        initialLoss:InitialLoss,
                        initialLossRecharge:InitialLossRecharge,
                        hydroReduct:HydroReduct,
                        routing:Routing,
                        interpolate:InterpolatMethod,
                        avgWW: scalesdata.avgWW,
                        day: scalesdata.day,
                        year: scalesdata.year,
                        scalesDayData: scalesdata.scalesDayData,
                        dryData: scalesdata.dryData,
                        decayFactor: DecayFactor,
                        srcThresh: SRCThresh,
                        datastring: scalesdata.datastring
                    })                      
                    const decomprequest = await client.post<any,any>('decomposition',decomppayload)

                    if(Object.keys(decomprequest).length === 0){
                        dispatch({type: ACTIONS.SET_ALERT, payload:{
                            title: 'Error decomposing data',
                            severity:'error',
                            message: 'Something went wrong.'
                        }})
                        dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
                    } else {
        
                        dispatch({type: ACTIONS.SET_STATE, payload:{handle:'decompData', state: decomprequest.decomposedData}})
                        var days = fitScalesToDataTable(scalesdata.day)
                        var years = fitScalesToDataTable(scalesdata.year)
                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'scaleLabel',state:["Hours of the day","Month of the year"]}})
                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'scaleContext',state:[days,years]}})
                        dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
                    }         
                }
            }   
        } catch (error) {
            console.log(error)
            console.error(error)
        } finally {
        }
    }

    const fitScalesToDataTable = (obj: any[]) => {

        var columns = [
            {
                field: 'rowHeader',
                headerName: '',
                width: 100
            }
        ]

        var rows: any = [
            {
                id: 1,
                rowHeader: "Weekday",
            },
            {
                id: 2,
                rowHeader: "Weekend"
            }
        ]
        
        obj.map(x=>{
            columns.push({
                field: `${x.index}`,
                headerName: `${x.index}`,
                width: 70
            })
            rows[0][`${x.index}`] = x.weekday.toFixed(4)
            rows[1][`${x.index}`] = x.weekend.toFixed(4)
        })
        return {
            columns,
            rows
        }
    }


    
        useEffect(()=>{
            if(state.decompData.length > 0){
                // get Unique data object
                
                var decomp = state.decompData.map(x=>{
                    delete x.totalFlow
                    return x
                })
    
                var objKeys = Object.keys(decomp[0])
    
                objKeys.shift()
                var dataset = objKeys.map((x: string)=>{
                    return{
                        id: x,
                        source: decomp.map((y:any)=>{
                            return [y.dateTime,y[x]]
                        })
                    }
                })
    
                dispatch({type:ACTIONS.SET_STATE, payload:{handle:'dataset', state: dataset}})
    
    
                /**
                 * Order
                 * 
                 * rain
                 * measure q
                 * frc
                 * ww
                 * src
                 */
    
                var cfg = 
                {
                    "src":{
                        order: 1,
                        header: 'SRC',
                        type: 'area',
                        color: '#286cbf',
                        axis: 'left'
                    },
                    "ww":{
                        order: 2,
                        header: 'Wastewater',
                        type: 'area',
                        color: '#b78f40',
                        axis: 'left'
                    },
                    "frc":{
                        order: 3,
                        header: 'FRC',
                        type: 'area',
                        color: '#f2b64f',
                        axis: 'left'
    
                    },
                    "measuredFlow": {
                        order: 4,
                        header: 'Corrected Flow',
                        type: 'line',
                        color: '#ff0000',
                        axis: 'left'
    
                    },
                    "measuredRain": {
                        order: 5,
                        header: 'Observed Rain',
                        type: 'line',
                        color: '#49933b',
                        axis: 'right'
    
                    },
                    "originalFlowValue": {
                        order: 4,
                        header: 'Input Flow',
                        type: 'dash',
                        color: '#474545',
                        axis: 'left'
    
                    },
                    "correctedFlow": {
                        order: 7,
                        header: 'Corrected Point',
                        type: 'scatter',
                        color: '#000000',
                        axis: 'left'
    
                    }
                }
                dispatch({type:ACTIONS.SET_STATE, payload:{handle:'chartConfig', state: cfg}})
            }
        },[state.decompData])


        const downloadData = () => {
            util.DownloadCSV(state.decompData);
        }
    
        const downloadTemplate = () => {
            util.DownloadTemplate(["datetime", "flowData", "rainData"])
        }
    



















    // useEffect(()=>{
    //     if(state.selected){
    //         var days = fitScalesToDataTable(state.selected.profile.profile.day)
    //         var years = fitScalesToDataTable(state.selected.profile.profile.year)
    //         dispatch({type:ACTIONS.SET_STATE, payload:{handle:'scaleLabel',state:["Hours of the day","Month of the year"]}})
    //         dispatch({type:ACTIONS.SET_STATE, payload:{handle:'scaleContext',state:[days,years]}})
    //     }
    // },[state.selected])


    //Don't need to fetch profiles anymore
    // const getProfiles = async() => {
    //     try {
    //         var response = await client.get<any[]>('/profile')
    //         if(response){
    //             var profileList = Object.keys(response).map((key) => {
    //                 return {
    //                     id: response[parseFloat(key)].id,
    //                     profile: JSON.parse(atob(response[parseFloat(key)].profile)),
    //                     profileId: response[parseFloat(key)].profileId
    //                 }
    //             });
    //             dispatch({type:ACTIONS.UPDATE_PROFILES, payload: profileList });
    //         }
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    // const updateProfiles = async(payload: any[]) => {
    //     try {
    //         var request = payload.map(obj => {return btoa(JSON.stringify(obj))});
    //         await client.post('/profile/update',request)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    // const confirmDeleteProfile = async (DeleteScale: boolean) => {
    //     if(DeleteScale)
    //         await DeleteProfile()
    //     dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showConfirmationBox',state:false}})
    // }

    // const ScaleNew = async (json: any) => {
    //     try {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})

    //         var payload = JSON.stringify({
    //             rainLowThresh: json.rainLowThresh,
    //             flowFrac: json.flowFrac,
    //             observedAvgFlow: json.observedAvgFlow,
    //             decayFactor: json.decayFactor,
    //             srcThresh: json.srcThresh,
    //             data: json.data
    //         })

    //         const scales = await client.post<any,any>('/decomposition/scales',payload)

    //          if(Object.keys(scales).length === 0){
    //             dispatch({type: ACTIONS.SET_ALERT, payload:{
    //                 title: 'Error creating scales',
    //                 severity:'error',
    //                 message: 'Something went wrong when creating your scales. Check your inputs.'
    //             }})
    //         } else {

    //             var newProfiles = [
    //                 {
    //                     name: json.name,
    //                     profile: {
    //                         avgWW: scales.avgWW,
    //                         day: scales.day,
    //                         year: scales.year,
    //                         scalesDayData: scales.dayData,
    //                         dryData: scales.processedDryDays, //Check this for the profiles
    //                         decayFactor: json.decayFactor,
    //                         srcThresh: json.srcThresh,
    //                         datastring: scales.datastring
    //                     }
    //                 }
    //             ]
    //             await updateProfiles(newProfiles)
    //         }    

    //         dispatch({type: ACTIONS.SET_DEBUG_DATA, payload: scales});            

    //     } catch (error) {
    //         console.error(error)
    //     } finally {
    //         setTimeout(()=>{ getProfiles().then(()=>{
    //             dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
    //         })}, 2000);
    //     }
    // }

    // const ScaleDebug = async (json: any) => {
    //     try {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})

    //         var payload = JSON.stringify({
    //             rainLowThresh: json.rainLowThresh,
    //             flowFrac: json.flowFrac,
    //             observedAvgFlow: json.observedAvgFlow,
    //             decayFactor: json.decayFactor,
    //             srcThresh: json.srcThresh,
    //             data: json.data
    //         })

    //         const scales = await client.post<any,any>('/debug/scales',payload)

    //          util.DownloadCSV(scales.preprocessedFlow, "PreprocessedFlowData-");
    //          util.DownloadCSV(scales.dryDays, "DryDays-");
    //          util.DownloadCSV(scales.infiltrationCalc, "CalculatedInfiltration-");
    //          util.DownloadCSV(scales.revisedInfiltrationCalcIt1, "It1RevisedCalculatedInfiltration-");
    //          util.DownloadCSV(scales.revisedInfiltrationCalcIt2, "It2RevisedCalculatedInfiltration-");
    //          util.DownloadCSV(scales.hourlyScaleExport, "HourlyScales-");

    //          if(Object.keys(scales).length === 0){
    //             dispatch({type: ACTIONS.SET_ALERT, payload:{
    //                 title: 'Error creating scales',
    //                 severity:'error',
    //                 message: 'Something went wrong when creating your scales. Check your inputs.'
    //             }})
    //         } else {

    //             var newProfiles = [
    //                 {
    //                     name: json.name,
    //                     profile: {
    //                         avgWW: scales.avgWW,
    //                         day: scales.day,
    //                         year: scales.year,
    //                         scalesDayData: scales.dayData,
    //                         dryData: scales.revisedInfiltrationCalcIt2, //Check this for the profiles
    //                         decayFactor: json.decayFactor,
    //                         srcThresh: json.srcThresh,
    //                         datastring: scales.datastring
    //                     }
    //                 }
    //             ]
    //             await updateProfiles(newProfiles)
    //         }    

    //         dispatch({type: ACTIONS.SET_DEBUG_DATA, payload: scales});
    //         dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showDebugBox', state:true}})           

    //     } catch (error) {
    //         console.error(error)
    //     } finally {
    //         setTimeout(()=>{ getProfiles().then(()=>{
    //             dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
    //         })}, 2000);
    //     }
    // }

    // const DeleteProfile = async() => {
    //     try {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})

    //         var request = state.selected.profileId
    //         await client.post('/profile/delete',request)

    //         dispatch({type:ACTIONS.CLEAR_SELECTION, payload: undefined})
            
    //     } catch (error) {
    //         console.error(error)
    //     } finally {
    //         setTimeout(()=>{ getProfiles().then(()=>{
    //             dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
    //         })}, 2000);
    //     }
    // }

    // const ExecuteDecompositionOK = async(file: any) => {
    //     try {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})

    //         var request = {
    //             ...state.selected?.profile.profile,
    //             ...file
    //         }

    //         var response = await client.post<any,any>('/decomposition',JSON.stringify(request))

    //         if(Object.keys(response).length === 0){
    //             dispatch({type: ACTIONS.SET_ALERT, payload:{
    //                 title: 'Error decomposing data',
    //                 severity:'error',
    //                 message: 'Something went wrong.'
    //             }})
    //         } else {

    //             dispatch({type: ACTIONS.SET_STATE, payload:{handle:'decompData', state: response.decomposedData}})
    //         }    
    //     } catch (error) {
    //         console.log(error)
    //         console.error(error)
    //     } finally {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
    //     }
    // }


    // const ExecuteDecompositionDebug = async(file: any) => {
    //     try {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})

    //         var request = {
    //             ...state.selected?.profile.profile,
    //             ...file
    //         }

    //         var response = await client.post<any,any>('/debug',JSON.stringify(request))

    //         if(Object.keys(response).length === 0){
    //             dispatch({type: ACTIONS.SET_ALERT, payload:{
    //                 title: 'Error decomposing data',
    //                 severity:'error',
    //                 message: 'Something went wrong.'
    //             }})
    //         } else {
    //             util.DownloadCSV(response.fullData, "DecomposedDayData-");
    //             util.DownloadCSV(response.decompHourData, "DecomposedHourData-");

    //             dispatch({type: ACTIONS.SET_STATE, payload:{handle:'decompData', state: response.returnObj}})
    //         }    
    //     } catch (error) {
    //         console.log(error)
    //         console.error(error)
    //     } finally {
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
    //     }
    // }

    // useEffect(()=>{
    //     if(auth != ''){
    //         dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})
    //         getProfiles().then(()=>{
    //             dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
    //         })
    //     }
    // },[])



    return (
        <div className="main-root">
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                className="main-container"
            >
                <Grid item lg={2} className="main-menu">
                    <Paper className="main-paper">
                        <Grid container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Button 
                                    endIcon={<TextSnippetIcon />}
                                    className="menu-button2"
                                    variant="contained"
                                    onClick={()=>{
                                        fetch('HydroSample.json').then((file) => file.json()).then((data) => setInputData(JSON.parse(data)))                                        
                                    }}
                                >
                                    Load Sample Data
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    endIcon={<CloudDownloadOutlinedIcon />}
                                    className="menu-button"
                                    variant="contained"
                                    onClick={()=>{
                                        downloadTemplate()
                                    }}
                                >
                                    Data Template
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    endIcon={<AddIcon />}
                                    className= "menu-button"
                                    variant="contained"
                                    component ="label"
                                >
                                    Upload Data
                                    <input hidden type="file" onChange={(e) => onFileChange(e)} onInput={(e) => onFileChange(e)} />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button"
                                    variant="contained"
                                    endIcon={<DoubleArrowIcon />}
                                    sx={{backgroundColor: ButtonColor, '&:hover': {backgroundColor: DecompButtonHover}}}
                                    onClick={()=>{
                                        sendDecomposition(InputData)                                    
                                    }}
                                >
                                    Decompose
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button"
                                    variant="contained"
                                    sx={{backgroundColor: '#D43A18', marginTop: '50px'}}
                                    onClick={() => {
                                        setInputData(undefined)
                                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'dataset', state: undefined}})
                                        dispatch({type: ACTIONS.SET_STATE, payload:{handle:'decompData', state: []}})
                                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'scaleLabel',state:[]}})
                                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'scaleContext',state:[]}})                            
                                     }}
                                >
                                    Unload Data
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button"
                                    variant="contained"
                                    sx={{backgroundColor: '#0084B2'}}
                                    onClick={() =>setNavigation('/landing')}
                                >
                                    Method Selection
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                            <Tooltip title ="The average flowrate of wastewater for the year">
                                <TextField
                                    type="number"
                                    label="Ave Annual Wastewaster"
                                    value={AveWastewater}
                                    fullWidth
                                    sx={{marginTop: '50px'}}
                                    onChange={(event) => { setAveWastewater(parseFloat(event.target.value)) }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">m3/s</InputAdornment>,
                                    }}
                                />
                            </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                            <Tooltip title ="The fast run off area for the catchment">
                                <TextField
                                    type="number"
                                    label="FRC Area"
                                    fullWidth
                                    value={FRCArea}
                                    onChange={(event) => { setFRCArea(parseFloat(event.target.value)) }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">ha</InputAdornment>,
                                    }}
                                />
                            </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button"
                                    variant="contained"
                                    sx={{backgroundColor: '#53A545', marginTop: '50px'}}
                                    onClick={() => {                                       
                                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showCatchProp', state:!state.showCatchProp}})
                                    }
                                    }
                                >
                                    Catchment Properties
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={10} className="main-content">
                    <Paper className="main-paper">
                        <Grid container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item xs={12} className="main-chart">
                                {state.decompData.length > 0 ? 
                                    <Tooltip title="Download CSV">
                                        <IconButton onClick={()=>downloadData()}size="medium">
                                            <SimCardDownloadOutlinedIcon fontSize="inherit" />
                                        </IconButton> 
                                    </Tooltip>
                                : <></>}
                                <StackedLineChart 
                                    data={state.dataset} 
                                    configuration={state.chartConfig}
                                    units={["m3/s","mm"]} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">{state.selected ? `Average Wastewater flow: ${state.selected.profile.profile.avgWW.toFixed(4)}`:``}</Typography>
                                <TabPanel contexts={state.scaleContext} labels={state.scaleLabel} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <LoadingOverlay state={state.loading} />
            <SnackAlert 
                state={state.alert.toggled}
                title={state.alert.title}
                message={state.alert.message}
                severity={state.alert.severity}
                closeCallback={()=>{
                    dispatch({type:ACTIONS.CLOSE_ALERT, payload:{}})
                }}
            />
            <CatchProperty
                state={state.showCatchProp}
                title="Set Catchment Properties"
                toggleDialog={()=>{
                    dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showCatchProp', state:!state.showCatchProp}})
                }}
                callback={(file:any) => {
                    dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showCatchProp', state:!state.showCatchProp}})
                    updateCatchProp(file)
                }}
                catchInput = {catchInput}
                defaultProps = {initalState}
            />
        </div>
    )
}