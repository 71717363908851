import React, { createContext, useEffect, useState } from 'react';

import {
    Routes,
    Route,
    useNavigate,
    Navigate
  } from "react-router-dom";

import {
    Grid,
    AppBar,
    Toolbar,
    Typography,
} from '@mui/material';

import {
    Auth,
    Landing,
    Main,
    Main2
} from 'containers';

import {
    InfoContext
} from './partials/InfoContext';

import DHILogo from 'assets/img/logo.png';

import './style.css';

const appName = process.env.REACT_APP_APP_NAME;

interface AppContextInterface {
    actions: Function,
    state: boolean,
}

export const AppContext = createContext<AppContextInterface | null>(null);

export const App: React.FC = () => {
    const navigate = useNavigate();

    const [apiKey, setApiKey] = useState("");
    const [auth, setAuth] = useState(false);

    const appContextProps: AppContextInterface = {
        actions: setAuth,
        state: auth,
    }

    useEffect(() => {
        !auth ? navigate('/login') : navigate('/landing')      
    }, [auth]);

    const navigatePage = (internalUri: string) => {
        if(internalUri != "")
            navigate(internalUri)
    }


    return (
        <AppContext.Provider value={appContextProps}>
            <Grid>
                <AppBar position="fixed" className="appBar">
                    <Toolbar>
                        <Grid
                            container
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Typography variant="h6" noWrap>
                                    {appName}
                                </Typography>
                                <Grid display="flex" alignItems="baseline">
                                    <Typography>Powered by</Typography>
                                    <img
                                        className="dhiLogo"
                                        src={DHILogo}
                                        alt="logo"
                                        id="logo"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                {auth ? <InfoContext color="white" /> : <></>}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <main className="content">
                    <div className="toolbar" />
                    <Routes>
                        <Route path="/login" element={<Auth setAuth={setAuth} setApiKey={setApiKey} />} />
                        <Route path="/landing" element={<Landing setNavigation={navigatePage}/>} />
                        <Route path="/method1" element={<Main auth={apiKey} setNavigation={navigatePage}/>} />
                        <Route path="/method2" element={<Main2 auth={apiKey} setNavigation={navigatePage}/>} />
                        <Route path="*" to="/login" />
                    </Routes>
                </main>
            </Grid>
        </AppContext.Provider>
    );
};
