import React, { useState } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { LoginForm } from 'components/Auth';

import './style.css';

const apiUrl = process.env.REACT_APP_API_BASE;

type AuthProps = {
  setApiKey: Function,
  setAuth: Function
}

type AuthHandleProps = {
  userName: string,
  password: string
}

export const Auth: React.FC<AuthProps> = ({ setApiKey, setAuth }) => {
  const [loading, setLoading] = useState(false);

  const handleAuth = async ({ userName, password }: AuthHandleProps) => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${apiUrl}/login`,
        {
          userName,
          password,
        },
      );
      setLoading(false);
      // console.log(response);

      if (response.status === 200) {
        setApiKey(response.data.apiKey);
        setAuth(true);
      } else {
        console.error('show error to user');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Box className="page">
      <LoginForm handleAuth={handleAuth} loading={loading} />
    </Box>
  );
};
