import moment from "moment"

export class Utilities {
	
	ReadCSVtoJson = async (path) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader
			fileReader.readAsText(path, "UTF-8")
	
			fileReader.onload = e => {
				var json = this.#csv2json(e.target.result, ";");
				resolve(json)
			}
		})
	}

	ValidateDateTimeFormat = (dateString, template) => {
		return moment(dateString,template,true).isValid();
	}

	ValidateJson = (json, template) => {
		var incomingHeaders = Object.keys(json[0])
		if (JSON.stringify(template) == JSON.stringify(incomingHeaders)) {
			return true;
		} else {
			return false;
		}
	}


	DownloadCSV = (json,prefix="") => {
		var strDate = new Date(Date.now()).toISOString()

		strDate = strDate.split(".")[0];
		strDate = strDate.replace("T", " ");
		strDate = strDate.replace(/:/g, "-");
		var saveName = strDate

		function LocalDecimalSep() {
			var n = 1.1;
			n = n.toLocaleString().substring(1, 2);
			return n;
		}
		var localSep = LocalDecimalSep();
		var csv = this.#json2csv(json)
		csv = csv.replaceAll(",",localSep)
		csv = csv.replaceAll(".",localSep)

		let blob = new Blob([csv], { type: "application/text" });
		this.#saveBlobToFile(blob, prefix + saveName + ".csv")
	}

	DownloadTemplate = (template) => {
		var csv = template.join(';')
		let blob = new Blob([csv], { type: "application/text" });
		this.#saveBlobToFile(blob, "data-template.csv")
	}
	

	#saveBlobToFile = (object, fileName) => {
		const url = window.URL.createObjectURL(object);
		this.createDownloadURL(url, fileName);
		setTimeout(() => {
			window.URL.revokeObjectURL(url);
		}, 1000);
	};

	createDownloadURL = (data, fileName) => {
		const a = document.createElement('a');
		a.href = data;
		a.download = fileName;
		document.body.appendChild(a);
		a.style = 'display: none';
		a.click();
		a.remove();
	};

	#json2csv = (json) => {
		const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
		const header = Object.keys(json[0])
		const csvObj = [
			header.join(";"), // header row first
			...json.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(";"))
		].join('\r\n')
	
		return csvObj;
	}

	#csv2json = (csv, separator = ";") => {
		var lines = csv.split("\n");

		if(lines[lines.length-1] === ''){
			lines.pop();
		}
	
		var result = [];
	
		// NOTE: If your columns contain commas in their values, you'll need
		// to deal with those before doing the next step 
		// (you might convert them to &&& or something, then covert them back later)
		// jsfiddle showing the issue https://jsfiddle.net/
		lines[0] = lines[0].replace("\r", "")
		var headers = lines[0].split(separator);

		//This is a split that allows the data to be processed even if there is no rain present
		if(headers.length === 2){
			for (var i = 1; i < lines.length; i++) {
		
				var obj = {};
		
				lines[i] = lines[i].replace("\r", "")
				lines[i] = lines[i].replaceAll(",", ".")
		
				var currentline = lines[i].split(separator);	
	
				//This is a check to see if the flow or rain is a number, and if NaN, an error is thorwn with the line number for the user to check. The headers 1(flow) and 2(rain) are hard coded here.
				let dateCheck = new Date(currentline[0])
				if(isNaN(currentline[1]) || !(dateCheck >= 0)){
					var error = {};
					error['error'] = i
					return error;
				}			
		
				for (var j = 0; j < headers.length; j++) {
					
					obj[headers[j]] = isNaN(currentline[j]) ? currentline[j] : parseFloat(currentline[j]);
				}
				result.push(obj);
			}
		}
		else if (headers.length === 3){
			for (var i = 1; i < lines.length; i++) {
		
				var obj = {};
		
				lines[i] = lines[i].replace("\r", "")
				lines[i] = lines[i].replaceAll(",", ".")
		
				var currentline = lines[i].split(separator);	
	
				//This is a check to see if the flow or rain is a number, and if NaN, an error is thorwn with the line number for the user to check. The headers 1(flow) and 2(rain) are hard coded here.
				let dateCheck = new Date(currentline[0])
				if(isNaN(currentline[1]) || isNaN(currentline[2]) || !(dateCheck >= 0)){
					var error = {};
					error['error'] = i
					return error;
				}			
		
				for (var j = 0; j < headers.length; j++) {
					
					obj[headers[j]] = isNaN(currentline[j]) ? currentline[j] : parseFloat(currentline[j]);
				}
				result.push(obj);
			}
		}
		
		return result; //JSON
	}
}
