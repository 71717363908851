import React, { useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';

import './style.css';

import DarkLogo from 'assets/img/DHI_Logo_Dark.png';

type AppProps = {
  handleAuth: Function,
  loading: boolean
}

export const LoginForm: React.FC<AppProps> = ({ handleAuth, loading }) => {
  const [state, setState] = useState({
    error: false,
    username: '',
    password: '',
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    handleAuth({ userName: state.username, password: state.password });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} md={4}>
        <Paper>
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            p={5}
            onSubmit={(e: any)=>handleSubmit(e)}
          >
            <div className="logo">
              <img alt="DHI Logo" src={DarkLogo} />
            </div>
            <br />
            <TextField
              variant="outlined"
              className="inputField"
              onChange={e => setState({ ...state, username: e.target.value })}
              value={state.username}
              label="Username"
              required
            />
            <br />
            <TextField
              error={!!state.error}
              variant="outlined"
              className="inputField"
              onChange={e => setState({ ...state, password: e.target.value })}
              value={state.password}
              label="Password"
              type="password"
              required
              helperText={state.error}
            />
            <br />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="submitButton"
              fullWidth
              disabled={loading}
              startIcon={loading && <CircularProgress size={16} />}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
