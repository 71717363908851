import React from 'react'

import ReactECharts from 'echarts-for-react';

interface StackedLineChartProps {
    data: any | undefined,
    configuration: any | undefined
    units: any | undefined
}

export const StackedLineChart: React.FC<StackedLineChartProps> = ({ data, configuration, units }) => {

    if (data == undefined || configuration == undefined) {
        return (<div></div>)
    }


    interface DynamicObject {
        [key: string]: any
    }

    var series = data.map((x: any, i: number) => {
        var currentConfig = configuration[x.id]

        var defConfig: DynamicObject = {
            type: 'line',
            name: currentConfig.header,
            order: currentConfig.order,
            lineStyle: {
                color: currentConfig.color
            },
            itemStyle: {
                color: currentConfig.color
            },
            datasetId: x.id,
            sampling: 'lttb',
            showSymbol: false,
            encode: {
                x: "Date",
                y: currentConfig.header,
                tooltip: [x.id],
                itemName: currentConfig.header
            }
        }

        if (configuration[x.id].axis == "right") {
            defConfig.yAxisIndex = 1;
            defConfig.type = 'bar'
        }

        if (currentConfig.type == "area") {
            defConfig.stack = "Total"
            defConfig.areaStyle = {
                color: currentConfig.color
            };
        }

        if (currentConfig.type == "scatter") {
            defConfig.type = 'scatter'
        }

        if (currentConfig.type == "dash") {
            defConfig.lineStyle = {
                type: 'dashed',
                width: 2
            }
        }


        return defConfig;
    })

    var config = {
        dataset: data,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#505765'
                }
            }
        },
        title: {
            left: 'left',
            text: ''
        },
        legend: {
            show: true,
            left: '91%',
            top: '30%',
            orient: 'vertical'
        },
        toolbox: {
            feature: {
                restore: {},
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: false
        },
        yAxis: [
            {
                name: units[0],
                type: 'value'
            }, {
                name: units[1],
                type: 'value',
                // max:20,
                nameLocation: 'start',
                inverse: true
            }
        ],
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 10
            },
            {
                start: 0,
                end: 10
            }
        ],
        series: series.sort((a:any, b:any) => a.order > b.order ? 1 : -1)
    };

    return (
        <div style={{ height: '62vh' }}>
            <ReactECharts option={config} style={{ height: '100%', width: '98%' }} />
        </div>
    );
}