import React, { useState } from 'react'

import {
    Backdrop,
    CircularProgress
} from '@mui/material'

interface LoadingOverlayProps {
    state: boolean,
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ state }) => {
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}