import React, { useReducer, useEffect, useState } from 'react'

import {
    Button,
    IconButton,
    Tooltip,
    Grid,
    Paper,
} from '@mui/material';

import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddIcon from '@mui/icons-material/Add';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

import {
    LoadingOverlay,
    SnackAlert,
    StackedLineChart,
    LHprop
} from 'components'

import sampleData from '../../assets/LHSample.json'

import {
    Utilities,
    ApiClient,
} from 'utils'

import "./styles2.css";

import { reducer, ActionKind as ACTIONS } from './partials/reducer';
import moment from "moment"
import { json } from 'stream/consumers';



interface MainProps {
    auth: string,
    setNavigation: Function
}

const util = new Utilities;

export const Main2: React.FC<MainProps> = ({ auth, setNavigation }) => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        selected: undefined,
        profiles: [],
        debugData: [],
        decompData: [],
        dataset: undefined,
        chartConfig: undefined,
        showConfirmationBox: false,
        showNewScalesBox: false,
        showDecomposeBox: false,
        showDebugBox: false,
        showLHprop: false,
        newScalesFile: undefined,
        scaleContext: [],
        scaleLabel:[],
        alert: {
            toggled: false,
            title: '',
            severity: 'info',
            message: ''
        }
    });

    const initalState = {
        Alpha1: 0.999,
        Alpha2: 0.99,
        BaseSplit: 0.85
    }
    
    const [InputData, setInputData] = useState({});
    const [ButtonColor, setButtonColor] = useState("");
    const [DecompButtonHover, setDecompButtonHover] = useState("");
    const [RainDataPresent, setRainDataPresent] = useState(true);

    //Catchment Properties
    const [Alpha1, setAlpha1] = useState(initalState.Alpha1);
    const [Alpha2, setAlpha2] = useState(initalState.Alpha2);
    const [BaseSplit, setBaseSplit] = useState(initalState.BaseSplit);


    const updateProps = (file:any) => {
        setAlpha1(file.alpha1)
        setAlpha2(file.alpha2)
        setBaseSplit(file.basesplit)
    }

    const LHProps = {
        alpha1:Alpha1,
        alpha2:Alpha2,
        basesplit:BaseSplit
    }

    useEffect(() => {
        Object.keys(InputData).length === 0 ?  setButtonColor("#0D3958") : setButtonColor("#53A545")
        Object.keys(InputData).length === 0 ?  setDecompButtonHover('') : setDecompButtonHover("#1F6C06")
    }, [InputData])

    const onFileChange = async (event: any) => {

        if (event.target.files[0].type === 'text/csv') {
            var json = await util.ReadCSVtoJson(event.target.files[0]);
            setRainDataPresent(true)

            if(Object.keys(json)[0] === 'error'){
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `There is an error in the input file on line: ${json.error + 1}`
                }})
                event.target.value ='';
                return
            }

            if (json.length === 0) {
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `csv-file not valid, headers order and title must be: datetime, flowData, rainData`
                }})
                event.target.value ='';
                return
            }

            if(!moment(json[0].datetime,"YYYY-MM-DDTHH:mm:ss.SSS",true).isValid()){
                dispatch({type: ACTIONS.SET_ALERT, payload:{
                    title: "Check of data-file.",
                    severity: "warning",
                    message: `csv-file not valid, datetime format must be YYYY-MM-DDTHH:mm:ss.SSS`
                }})
                event.target.value ='';
                return
            }
            //This checks if rain was input
            if(Object.keys(json[0]).length === 2){
                for (let i = 0; i < json.length; i++) {
                    json[i] = Object.assign(json[i], {rainData : 0})
                }
                setRainDataPresent(false)
            }
            setInputData(json)
            event.target.value ='';
        }
        else{
            dispatch({type: ACTIONS.SET_ALERT, payload:{
                title: "Check of data-file.",
                severity: "warning",
                message: `Please upload a csv or text file`
            }})
            event.target.value ='';
            return
        }
    }

    const client = new ApiClient(auth,process.env.REACT_APP_API_BASE);

    const sendDecomposition = async(InputData: any) => {
        if(InputData === undefined){
            dispatch({type: ACTIONS.SET_ALERT, payload:{
                title: 'Input data missing',
                severity:'error',
                message: 'Please input data for decomposition'
            }})
        }
        else{            
            try{
                dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:true}})
                
                var payload = {
                    data : InputData,
                    alphaOne : Alpha1,
                    alphaTwo : Alpha2,
                    baseSplit: BaseSplit
                }
                var response = await client.post<any,any>('lhdecomposition',JSON.stringify(payload))
    
                if(Object.keys(response).length === 0){
                    dispatch({type: ACTIONS.SET_ALERT, payload:{
                        title: 'Error with decompostion.',
                        severity:'error',
                        message: 'Something went wrong when creating your scales. Check your inputs.'
                    }})
                } else if (Object.keys(response)[0] === 'inputFileError'){
                    dispatch({type: ACTIONS.SET_ALERT, payload:{
                        title: "Error with decompostion.",
                        severity: "error",
                        message: `There is a gap in the data that is larger than 1 hour at: ${response.inputFileError.location}`
                        }})
                }
                else {
                    dispatch({type: ACTIONS.SET_STATE, payload:{handle:'decompData', state: response.returnObj}})
                }
    
            } catch (error) {
            console.error(error)
            } finally {
                dispatch({type: ACTIONS.SET_STATE, payload:{handle:'loading', state:false}})
            }
        }
    }

    useEffect(()=>{
        if(state.decompData.length > 0){            
            //The chart is plotted differently if there is rain
            if(RainDataPresent){
                var decomp = state.decompData.map(x=>{
                    return x
                })
    
                var objKeys = Object.keys(decomp[0])
    
                objKeys.shift()
                var dataset = objKeys.map((x: string)=>{
                    return{
                        id: x,
                        source: decomp.map((y:any)=>{
                            return [y.date,y[x]]
                        })
                    }
                })    
                dispatch({type:ACTIONS.SET_STATE, payload:{handle:'dataset', state: dataset}})
    
                let cfg = 
                {
                    "baseline":{
                        order: 1,
                        header: 'Baseflow',
                        type: 'area',
                        color: '#89C2D6',
                        axis: 'left'
                    },
                    "src":{
                        order: 2,
                        header: 'SRC',
                        type: 'area',
                        color: '#286cbf',
                        axis: 'left'
                    },
                    "ww":{
                        order: 3,
                        header: 'Wastewater',
                        type: 'area',
                        color: '#9C8530',
                        axis: 'left'
                    },
                    "frc":{
                        order: 4,
                        header: 'FRC',
                        type: 'area',
                        color: '#f2b64f',
                        axis: 'left'
                    },
                    "correctedFlow": {
                        order: 5,
                        header: 'Corrected Flow',
                        type: 'line',
                        color: '#ff0000',
                        axis: 'left'    
                    },
                    "rain": {
                        order: 6,
                        header: 'Observed Rain',
                        type: 'line',
                        color: '#49933b',
                        axis: 'right'    
                    },
                    "flow": {
                        order: 4,
                        header: 'Input Flow',
                        type: 'dash',
                        color: '#474545',
                        axis: 'left'      
                    }  
                }
                dispatch({type:ACTIONS.SET_STATE, payload:{handle:'chartConfig', state: cfg}})
            } 
            else if (!RainDataPresent){
                var decomp = state.decompData.map(x=>{
                    delete x.rain
                    return x
                })
    
                var objKeys = Object.keys(decomp[0])
    
                objKeys.shift()
                var dataset = objKeys.map((x: string)=>{
                    return{
                        id: x,
                        source: decomp.map((y:any)=>{
                            return [y.date,y[x]]
                        })
                    }
                })
    
                dispatch({type:ACTIONS.SET_STATE, payload:{handle:'dataset', state: dataset}})
    
                let cfg = 
                {
                    "baseline":{
                        order: 1,
                        header: 'Baseflow',
                        type: 'area',
                        color: '#89C2D6',
                        axis: 'left'
                    },
                    "src":{
                        order: 2,
                        header: 'SRC',
                        type: 'area',
                        color: '#286cbf',
                        axis: 'left'
                    },
                    "ww":{
                        order: 3,
                        header: 'Wastewater',
                        type: 'area',
                        color: '#9C8530',
                        axis: 'left'
                    },
                    "frc":{
                        order: 4,
                        header: 'FRC',
                        type: 'area',
                        color: '#f2b64f',
                        axis: 'left'
                    },
                    "correctedFlow": {
                        order: 5,
                        header: 'Corrected Flow',
                        type: 'line',
                        color: '#ff0000',
                        axis: 'left'    
                    },
                    "flow": {
                        order: 4,
                        header: 'Input Flow',
                        type: 'dash',
                        color: '#474545',
                        axis: 'left'    
                    }
                }
                dispatch({type:ACTIONS.SET_STATE, payload:{handle:'chartConfig', state: cfg}})
            }
        }
    },[state.decompData])

    const downloadData = () => {
        util.DownloadCSV(state.decompData);
    }

    const downloadTemplate = () => {
        util.DownloadTemplate(["datetime", "flowData", "rainData"])
    }

    return (
        <div className="main-root2">
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                className="main-container2"
            >
                <Grid item lg={2} className="main-menu2">
                    <Paper className="main-paper2">
                        <Grid container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Button 
                                    endIcon={<TextSnippetIcon />}
                                    className="menu-button2"
                                    variant="contained"
                                    onClick={()=>{
                                        setInputData(sampleData);
                                        //fetch('LHSample.json').then((file) => file.json()).then((data) => setInputData(JSON.parse(data)))                                        
                                    }}
                                >
                                    Load Sample Data
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    endIcon={<CloudDownloadOutlinedIcon />}
                                    className="menu-button2"
                                    variant="contained"
                                    onClick={()=>{
                                        downloadTemplate()
                                    }}
                                >
                                    Data Template
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    endIcon={<AddIcon />}
                                    className="menu-button2"
                                    variant="contained"
                                    component="label"
                                    >
                                    Upload Data
                                    <input hidden type="file" onChange={(e) => onFileChange(e)}/>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button2"
                                    variant="contained"
                                    endIcon={<DoubleArrowIcon />}
                                    sx={{backgroundColor: ButtonColor, '&:hover': {backgroundColor: DecompButtonHover}}}
                                    onClick={()=>{
                                        sendDecomposition(InputData)
                                    }}
                                >
                                    Decompose
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button2"
                                    variant="contained"
                                    sx={{backgroundColor: '#D43A18', marginTop: '50px'}}
                                    onClick={() => {
                                        setInputData({})
                                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'dataset', state: undefined}})
                                        dispatch({type: ACTIONS.SET_STATE, payload:{handle:'decompData', state: []}})
                                    }}
                                >
                                    Unload Data
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button2"
                                    variant="contained"
                                    sx={{backgroundColor: '#0084B2'}}
                                    onClick={() =>setNavigation('/landing')}
                                >
                                    Method Selection
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="menu-button2"
                                    variant="contained"
                                    sx={{backgroundColor: '#53A545', marginTop: '50px'}}
                                    onClick={() =>
                                        dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showLHprop', state:!state.showLHprop}})
                                    }
                                >
                                    Catchment Properties
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={10} className="main-content2">
                    <Paper className="main-paper2">
                        <Grid container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item xs={12} className="main-chart2">
                                {state.decompData.length > 0 ? 
                                    <Tooltip title="Download CSV">
                                        <IconButton onClick={()=>downloadData()}size="medium">
                                            <SimCardDownloadOutlinedIcon fontSize="inherit" />
                                        </IconButton> 
                                    </Tooltip>
                                : <></>}
                                <StackedLineChart 
                                    data={state.dataset} 
                                    configuration={state.chartConfig} 
                                    units={["",""]} 
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <LoadingOverlay state={state.loading} />
            <SnackAlert 
                state={state.alert.toggled}
                title={state.alert.title}
                message={state.alert.message}
                severity={state.alert.severity}
                closeCallback={()=>{
                    dispatch({type:ACTIONS.CLOSE_ALERT, payload:{}})
                }}
            />
            <LHprop
                state={state.showLHprop}
                title="Set Catchment Properties"
                toggleDialog={()=>{
                    dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showLHprop', state:!state.showLHprop}})
                }}
                callback= {(file:any) => {
                    dispatch({type:ACTIONS.SET_STATE, payload:{handle:'showLHprop', state:!state.showLHprop}})
                    updateProps(file)
                }}
                LHProps = {LHProps}
                defaultProps = {initalState}
            />
        </div>
    )
}