import React, { useState, useEffect } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from '@mui/material';

import {
    Utilities
} from 'utils';
import { Main } from 'containers';

interface CachmentDialogProps {
    state: boolean,
    title: string,
    toggleDialog: Function,
    callback: Function,
    catchInput: any,
    defaultProps: any
}

export const CatchProperty: React.FC<CachmentDialogProps> = ({ state, title, toggleDialog, callback, catchInput, defaultProps }) => {

    const [RainLowThresh, setRainLowThresh] = useState(catchInput.rainLowThresh)
    const [FlowFrac, setFlowFrac] = useState(catchInput.flowFrac)
    const [DecayFactor, setDecayFactor] = useState(catchInput.decayFactor)
    const [SRCThresh, setSRCThresh] = useState(catchInput.srcThresh)
    const [InitialLoss, setInitialLoss] = useState(catchInput.initialLoss)
    const [InitialLossRecharge, setInitialLossRecharge] = useState(catchInput.initialLossRecharge)
    const [HydroReduct, setHydroReduct] = useState(catchInput.hydroReduct)
    const [Routing, setRouting] = useState(catchInput.routing)
    const [InterpolatMethod, setInterpolate] = useState(catchInput.interpolate)

    const handleOk = () => {
        callback({
            rainLowThresh: RainLowThresh,
            flowFrac: FlowFrac,
            decayFactor: DecayFactor,
            srcThresh: SRCThresh,
            initialLoss:InitialLoss,
            initialLossRecharge: InitialLossRecharge,
            hydroReduct: HydroReduct,
            routing: Routing,
            interpolate: InterpolatMethod    
        })
    }

    const resetProps = () => {
        setRainLowThresh(defaultProps.RainLowThresh)
        setFlowFrac(defaultProps.FlowFrac)
        setDecayFactor(defaultProps.DecayFactor)
        setSRCThresh(defaultProps.SRCThresh)
        setInitialLoss(defaultProps.InitialLoss)
        setInitialLossRecharge(defaultProps.InitialLossRecharge)
        setHydroReduct(defaultProps.HydroReduction)
        setRouting(defaultProps.Routing)
        setInterpolate(defaultProps.InterpolatMethod)
    }

    useEffect(()=>{
        setRainLowThresh(catchInput.rainLowThresh)
        setFlowFrac(catchInput.flowFrac)
        setDecayFactor(catchInput.decayFactor)
        setSRCThresh(catchInput.srcThresh)
        setInitialLoss(catchInput.initialLoss)
        setInitialLossRecharge(catchInput.initialLossRecharge)
        setHydroReduct(catchInput.hydroReduct)
        setRouting(catchInput.routing)
        setInterpolate(catchInput.interpolate)
    },[state])

    return (
        <Dialog
            open={state}
            onClose={() => toggleDialog()}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogContent>
                <br />
                <Grid container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Tooltip title ="Rain above this threshold is processed as a rain day">
                            <TextField
                                type="number"
                                label="Rain lower threshold"
                                fullWidth
                                value={RainLowThresh}
                                onChange={(event) => { setRainLowThresh(parseFloat(event.target.value)) }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title ="Minimum flow, as fraction of observed average WW flow">
                            <TextField
                                type="number"
                                label="Minimum flow fraction"
                                fullWidth
                                value={FlowFrac}
                                onChange={(event) => { setFlowFrac(parseFloat(event.target.value)) }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            label="Decay Factor"
                            fullWidth
                            value={DecayFactor}
                            onChange={(event) => { setDecayFactor(parseFloat(event.target.value)) }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title = "Percentage of observed average WW flow that is SRC">
                            <TextField
                                type="number"
                                label="Minimum threshold for SRC"
                                fullWidth
                                value={SRCThresh}
                                onChange={(event) => { setSRCThresh(parseFloat(event.target.value)) }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            label="Initail loss"
                            fullWidth
                            value={InitialLoss}
                            onChange={(event) => { setInitialLoss(parseFloat(event.target.value)) }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            label="Initail loss recharge"
                            fullWidth
                            value={InitialLossRecharge}
                            onChange={(event) => { setInitialLossRecharge(parseFloat(event.target.value)) }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm/h</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            label="Hydrological reduction factor"
                            fullWidth
                            value={HydroReduct}
                            onChange={(event) => { setHydroReduct(parseFloat(event.target.value)) }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title = "The routing of rain - 1 is no routing, 0 is full routing">
                            <TextField
                                type="number"
                                label="Routing"
                                fullWidth
                                value={Routing}
                                onChange={(event) => { setRouting(parseFloat(event.target.value)) }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Interpolation Method</InputLabel>
                                <Select
                                    labelId="simple-select-label"
                                    id="simple-select"
                                    value={InterpolatMethod}
                                    label="Interpolation Method"
                                    onChange={(event) => { setInterpolate(event.target.value) }}
                                >
                                    <MenuItem value={"InterpolateNatural"}>Natural</MenuItem>
                                    <MenuItem value={"InterpolatePchip"}>Pchip</MenuItem>
                                    <MenuItem value={"InterpolateAkima"}>Akima</MenuItem>
                                </Select>
                            </FormControl>
                            </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => handleOk()}>Ok</Button>
                <Button color="secondary" variant="contained" onClick={() => toggleDialog()}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={() => resetProps()}>Reset</Button>
            </DialogActions>
        </Dialog>
    );
}