import * as React from 'react';

import PropTypes from 'prop-types';

import {
    Tab,
    Tabs,
    Typography,
    Box
} from '@mui/material';

import { DataTable } from '../index'


interface TabContextProps {
    children: React.ReactNode,
    value: Number,
    index: Number
}

const TabContext: React.FC<TabContextProps> = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabcontext-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabContext.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: Number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabcontext-${index}`,
  };
}

interface TabPanelProps {
    labels: any[],
    contexts: any[]
}

export const TabPanel: React.FC<TabPanelProps> = ({ contexts, labels }) => {
  const [value, setValue] = React.useState(0);

  var context = contexts.map((obj,i)=>{
      return(
        <TabContext key={`tab-context-${i}`} value={value} index={i}>
            <DataTable key={`data-table-${i}`} cols={obj.columns} rows={obj.rows} />
        </TabContext>
      )
  })

  var label = labels.map((obj,i)=>{
      return <Tab key={`tab-key-${i}`} label={obj} {...a11yProps(i)} />
  })

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tab panel">
          {label}
        </Tabs>
      </Box>
      {context}
    </Box>
  );
}