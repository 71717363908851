import * as React  from "react"

enum ActionKind {
    SET_STATE = 'set-state',
    SET_ALERT = 'set-alert',
    SET_SELECTED = 'set-selected',
    SET_DEBUG_DATA = 'set-debug-data',
    CLOSE_ALERT = 'close-alert',
    UPDATE_PROFILES = 'update-profiles',
    CLEAR_SELECTION = 'clear-selection',
}

type ACTION = {
    type: ActionKind,
    payload: any
}

type STATE = {
    loading: boolean,
    selected: any,
    profiles: any[],
    decompData: any[],
    debugData: any[],
    dataset: any,
    chartConfig: any,
    showConfirmationBox: boolean,
    showNewScalesBox: boolean,
    showDecomposeBox: boolean,
    showDebugBox: boolean,
    showLHprop: boolean,
    newScalesFile: any,
    scaleContext: any[],
    scaleLabel: any[],
    alert: any
}

const reducer: React.Reducer<STATE,ACTION> = (state, action): STATE => {
    switch (action.type) {
        case ActionKind.SET_STATE:
            return {
                ...state,
                [action.payload.handle]: action.payload.state
            }
        case ActionKind.SET_ALERT:
            return {
                ...state,
                alert: {
                    toggled: true,
                    title: action.payload.title,
                    severity: action.payload.severity,
                    message: action.payload.message
                }
            }
        case ActionKind.SET_SELECTED:
            return {
                ...state,
                selected: state.profiles.find(x => x.profile.name == action.payload)
            }
        case ActionKind.CLOSE_ALERT:
            return{
                ...state,
                alert: {
                    ...state.alert,
                    toggled: false
                }
            }
        case ActionKind.UPDATE_PROFILES: 
            return {
                ...state,
                profiles: action.payload
            }

        case ActionKind.CLEAR_SELECTION:
            return {
                ...state,
                selected: undefined,
                scaleContext: [],
                scaleLabel:[]
            }

        case ActionKind.SET_DEBUG_DATA:

            return {
                ...state,
                debugData: action.payload
            }

        default:
            return state;
    }
}

export { reducer, ActionKind }