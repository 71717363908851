import React from 'react'

import {
    Snackbar,
    Alert,
    AlertTitle,
    AlertColor
} from '@mui/material'

interface SnackAlertProps {
    severity: AlertColor,
    message: string,
    title: string,
    state: boolean,
    closeCallback: Function
}

export const SnackAlert: React.FC<SnackAlertProps> = ({title, message, severity, state, closeCallback}) => {
    const handleClose = (event:any, reason:any) =>{
        if(reason == 'clickaway'){return;}
        closeCallback()
    }
    
    return (
        <Snackbar autoHideDuration={severity == 'error' ? 4000 : 4000} open={state} onClose={handleClose}>
            <Alert variant="filled" severity={severity}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
}