import React, { useState, useEffect } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    InputAdornment,
    Tooltip
} from '@mui/material';

import {
    Utilities
} from 'utils';
import { Main } from 'containers';

interface CachmentDialogProps {
    state: boolean,
    title: string,
    toggleDialog: Function,
    callback: Function,
    LHProps: any,
    defaultProps: any
}

export const LHprop: React.FC<CachmentDialogProps> = ({ state, title, toggleDialog, callback, LHProps, defaultProps }) => {

    const [Alpha1, setAlpha1] = useState(LHProps.alpha1)
    const [Alpha2, setAlpha2] = useState(LHProps.alpha2)
    const [BaseSplit, setBaseSplit] = useState(LHProps.basesplit);


    const handleOk = () => {
        callback({
            alpha1: Alpha1,
            alpha2: Alpha2,
            basesplit: BaseSplit
        })
    }

    const resetProps = () => {
        setAlpha1(defaultProps.Alpha1)
        setAlpha2(defaultProps.Alpha2)
        setBaseSplit(defaultProps.BaseSplit)
    }

    useEffect(()=>{
        setAlpha1(LHProps.alpha1)
        setAlpha2(LHProps.alpha2)
        setBaseSplit(LHProps.basesplit)
    },[state])

    return (
        <Dialog
            open={state}
            onClose={() => toggleDialog()}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogContent>
                <br />
                <Grid container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Tooltip title ="The alpha 1 value used in the L and H method">
                            <TextField
                                type="number"
                                label="Alpha 1"
                                fullWidth
                                value={Alpha1}
                                onChange={(event) => { setAlpha1(parseFloat(event.target.value)) }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title ="The aplha 2 factor used in the L and H method">
                            <TextField
                                type="number"
                                label="Alpha 2"
                                fullWidth
                                value={Alpha2}
                                onChange={(event) => { setAlpha2(parseFloat(event.target.value)) }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title ="The fraction of the min dry weather flow that is used as the baseline ">
                            <TextField
                                type="number"
                                label="Base Split"
                                fullWidth
                                value={BaseSplit}
                                onChange={(event) => { setBaseSplit(parseFloat(event.target.value)) }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => handleOk()}>Ok</Button>
                <Button color="secondary" variant="contained" onClick={() => toggleDialog()}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={() => resetProps()}>Reset</Button>
            </DialogActions>
        </Dialog>
    );
}