import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  CssBaseline
} from '@mui/material';

import { blueGrey, lightGreen, red } from '@mui/material/colors';

import { App } from 'containers';

const baseName = process.env.REACT_APP_BASE_NAME;

const MOUNT_NODE = document.getElementById('root');

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0D3958',
    },
    secondary: blueGrey,
    tertiary: lightGreen,
    quaternary: red
  },
});

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Router basename={baseName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Router>
    </React.StrictMode>,
    MOUNT_NODE,
  );
};

render();