import React,{ useState } from 'react'

import HelpIcon from '@mui/icons-material/Help';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    IconButton
} from '@mui/material';


interface InfoContextProps {
    color: string;
}

export const InfoContext: React.FC<InfoContextProps> = ({ color }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => { setOpen(true)};
    const handleClose = () => { setOpen(false)};

    return (
        <>
            <IconButton onClick={()=>{handleOpen()}} size="large" sx={{color: color}}>
                <HelpIcon fontSize="inherit" color="inherit"/>
            </IconButton>
            <Dialog open={open} onClose={()=>{handleClose()}} fullWidth maxWidth='md'>
                <DialogContent>
                    <DialogTitle>Information</DialogTitle>
                    <DialogContentText>
                        Formats required in csv-files: <br/>
                        Datetime fields: ex. 2021-12-24T14:00:00.000 [YYYY-MM-DDTHH:mm:ss.SSS] <br />
                        Data: ex. 0.314
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}