import React, { useState } from 'react';
import axios from 'axios';
import { LandingPage } from 'components/Landing';

import { Box,
  ButtonBase,
  Typography,
  styled,  
  Grid
} from '@mui/material';

import './style.css';

const apiUrl = process.env.REACT_APP_API_BASE;

type MethodProps = {
  setNavigation: Function
}

export const Landing: React.FC<MethodProps> = ({setNavigation}) => {
  
  return (
    <Box className="page">
      <LandingPage setNavigation={setNavigation}/>
    </Box>
  );
};
