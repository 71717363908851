import React,{ useState } from 'react'
import { DataGrid, GridRowProps, GridColDef } from '@mui/x-data-grid';

interface DataTableProps {
    rows: GridRowProps[],
    cols: GridColDef[]
}

export const DataTable: React.FC<DataTableProps> = ({ rows, cols }) => {
    const [page, setPage] = useState(0)
    return (
        <div style={{ display: 'flex', height: '25vh' }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid
                    page={page}
                    onPageChange={(newPage)=>setPage(newPage)}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    rowHeight={25}
                    rows={rows}
                    columns={cols}
                />
            </div>
        </div>
    );
}