import Axios, { AxiosInstance } from 'axios';

export interface IApiClient {
    post<TRequest, TResponse>(
        path: string,
        object: TRequest
    ): Promise<TResponse>;
    patch<TRequest, TResponse>(
        path: string,
        object: TRequest
    ): Promise<TResponse>;
    put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>;
    get<TResponse>(path: string): Promise<TResponse>;
}

export class ApiClient implements IApiClient {
    private client: AxiosInstance;

    protected createAxiosClient(
        token: string, baseUrl: string | undefined
    ): AxiosInstance {
        return Axios.create({
            baseURL: baseUrl,
            responseType: 'json' as const,
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/json',
                'ApiKey': token,
            },
            timeout: 5 * 60 * 1000,
        });
    }

    constructor(token: string, baseUrl: string | undefined) {
        this.client = this.createAxiosClient(token, baseUrl);
    }

    async post<TRequest, TResponse>(
        path: string,
        payload: TRequest
    ): Promise<TResponse> {
        try {
            const response = await this.client.post<TResponse>(path, payload);
            return response.data;
        } catch (error:any) {
            console.error(error)
            if(error.response.status === 415){
                return error.response.data
            }
        }
        return {} as TResponse;
    }

    async patch<TRequest, TResponse>(
        path: string,
        payload: TRequest
    ): Promise<TResponse> {
        try {
            const response = await this.client.patch<TResponse>(path, payload);
            return response.data;
        } catch (error) {
            console.error(error)
        }
        return {} as TResponse;
    }

    async put<TRequest, TResponse>(
        path: string,
        payload: TRequest
    ): Promise<TResponse> {
        try {
            const response = await this.client.put<TResponse>(path, payload);
            return response.data;
        } catch (error) {
            console.error(error)
        }
        return {} as TResponse;
    }

    async get<TResponse>(path: string): Promise<TResponse> {
        try {
            const response = await this.client.get<TResponse>(path);
            return response.data;
        } catch (error) {
            console.error(error)
        }
        return {} as TResponse;
    }
}