import React, { useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  ButtonBase,
  Typography,
  styled,
  IconButton, 
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import './style.css';

import Image1 from 'assets/img/method1.png';
import Image2 from 'assets/img/method2.png';

const images = [
  {
    url: Image1,
    title: 'DrainMan - Coming Soon!',
    width: '500px',
    method: 1,
    //navUrl: '/method1'
  },
  {
    url: Image2,
    title: '1&1 - Lyne and Hollick Method',
    width: '500px',
    method: 2,
    navUrl: '/method2'
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 500,
  marginInline : '20px',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 50%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.black,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

type AppProps = {
  setNavigation: Function,
}

export const LandingPage: React.FC<AppProps> = ({setNavigation}) => {

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const Method1 = `
    Drainman method coming soon!
  `;

  const Method2 = `
    The 1&1 - Lyne and Hollick Method is a simplified approach that only requires flow. The approach is based on the method developed by Lyn and Hollik.
    The method requires 1 input parameter: the alpha factor for the catchment area, which can be adjusted.
  `;  

	const DownloadTest = (filename:string) => {    
    fetch(filename).then(response => {
      response.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
  })
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    > 
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', minWidth: 300, maxWidth: '100%'}}>
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            onClick = {() => setNavigation(image.navUrl)}
            style={{
              width: image.width,
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {image.title}
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', minWidth: 300, maxWidth: '100%'}}>
        <Tooltip title = 'DrainMan Coming Soon!'>
          <IconButton color="primary" onClick={()=>{}}>
            <DownloadIcon/>
          </IconButton>       
        </Tooltip>
        <CustomWidthTooltip  title = {Method1}>
          <IconButton color="primary"  disableRipple={true}  sx={{marginRight: '15px'}}>
            <InfoIcon/>
          </IconButton>
        </CustomWidthTooltip >
        <CustomWidthTooltip  title = {Method2}>
          <IconButton color="primary" disableRipple={true} sx={{marginLeft: '15px'}}>
            <InfoIcon/>
          </IconButton>
        </CustomWidthTooltip >
        <Tooltip title = 'Data input requirements' onClick={()=>{DownloadTest('Input Requiremnts - Lyne and Hollick Method.pdf')}}>
          <IconButton color="primary">
            <DownloadIcon/>
          </IconButton>       
        </Tooltip>
      </Box>
    </Grid>
    </Grid>
  );
};
